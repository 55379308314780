import { FC, memo } from 'react'
import Image from 'next/image'
// import { useInView } from 'react-intersection-observer'
import styled from 'styled-components'
// import { Video } from './Video'
import { useDeviceType } from '../../hooks'
import type { TypeMedia, TypeVideoExternal } from '../../schema'

type TypeFigure = {
  readonly media: TypeMedia | TypeVideoExternal,
  readonly lazy?: boolean,
  readonly initialInView?: boolean,
  onClick?: any,
  readonly fill?: boolean,
  readonly outRef?: (node?: Element) => void,
  readonly className?: string,
  readonly sizes?: any,
  readonly quality?: number,
  readonly staticImage?: boolean
  readonly oldVersion?: boolean
}

const FigureStyled = styled.figure<any>`
  display: block;
  position: relative;
`

export const Figure: FC<TypeFigure> = memo(({ media, lazy = true, fill = true, onClick = null, className = '', outRef = null, sizes = '70vw', quality = 75, oldVersion = false }) => {
  const mediaType = useDeviceType()
  // const [ref, inView] = useInView({
  //   triggerOnce: true,
  //   rootMargin: '200px 0px', // TOP  RIGHT
  //   threshold: 0,
  //   initialInView
  // })

  if (!media) return <div className='placeholder' />
  if (media.type === 'video') return <></>
  // if(media.type === 'video') return <Video data={media} />
  if (media.type === 'svg') return (
    <FigureStyled className={className} {...onClick && { onClick }}  {...outRef && { ref: outRef }}>
      <Image src={media.src} alt={media.alt}
        layout={fill ? 'fill' : 'responsive'}
        {...!lazy && { priority: true }}
        {...!fill && { height: media.size.height }}
        {...!fill && { width: media.size.width }}
      />
    </FigureStyled>
  )
  // ref={ref}

  // console.log('initialInView', initialInView)
  return (
    <FigureStyled className={className} {...onClick && { onClick }}  {...outRef && { ref: outRef }}>
      {/* {inView && */}
      {oldVersion ?
        <img src={media[(mediaType === 'mobile' && media?.mobile) ? 'mobile' : 'default'].src} alt={media.alt} /> :
        <Image
          src={media?.[(mediaType === 'mobile' && media?.mobile) ? 'mobile' : 'default']?.src}
          alt={media?.alt}
          layout={fill ? 'fill' : 'responsive'}
          {...!lazy && { priority: true }}
          {...!fill && { height: media?.[(mediaType === 'mobile' && media?.mobile) ? 'mobile' : 'default'].size.height }}
          {...!fill && { width: media?.[(mediaType === 'mobile' && media?.mobile) ? 'mobile' : 'default'].size.width }}
          sizes={sizes}
          {...quality !== 75 && { quality }}
        />
      }
    </FigureStyled>
  )
})
