import { css } from 'styled-components'
import { mq, vw } from '.'

export const createUnderline = ({ height = 1, reverse = false, color = 'black'} = {}) => css`
  position: relative;

  span {
    position: absolute;
    bottom: 0;
    height: ${height}px;
    width: 0;
    background-color: ${({ theme }) => theme.colors[color]};
    left: unset;
    right: 0;
    transition: width 300ms ease, left 300ms ease, right 300ms ease;
  }

  ${reverse && `
    span {
      left: 0;
      right: unset;
      width: 100%;
    }
  `}

  &:hover,
  &.active {
    ${reverse ? `
      span {
        left: unset;
        right: 0;
        width: 0;
      }` : `
      span {
        left: 0;
        right: unset;
        width: 100%;
      }`}
  }
`

export const createTooltip = ({ color = '#F6A300' }) => css`

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    width: 0;
    height: 0;
    border: ${vw(9, 'mobile')} solid transparent;
    border-bottom-color: ${color};

    ${mq.greaterThan('tablet')`
      border: ${vw(9, 'desktop')} solid transparent;
      border-bottom-color: ${color};
    `}
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
    width: 0;
    height: 0;
    border: ${vw(4, 'mobile')} solid transparent;
    border-bottom-color: ${color};

    ${mq.greaterThan('tablet')`
      border: ${vw(4, 'desktop')} solid transparent;
      border-bottom-color: ${color};
    `}
  }
`

export function hex2Rgba (hex, alpha) {
  const r = parseInt(hex.substring(1, 3), 16)
  const g = parseInt(hex.substring(3, 5), 16)
  const b = parseInt(hex.substring(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const hideScrollbar = () => css`
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  &::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0);
    display: none;
  }
`

const defaultPlaceHolderStyles = (color) => css<any>`
  transition: opacity 300ms ${({ ease }) => ease};
  color: ${color ?? 'rgba(0, 0, 0, 0)'};
`

export const parsePlaceHolder = (styles, color = null) => (css`
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    ${defaultPlaceHolderStyles(color)}
    ${styles}
  }
  ::-moz-placeholder { /* Firefox 19+ */
    ${defaultPlaceHolderStyles(color)}
    ${styles}
  }
  :-ms-input-placeholder { /* IE 10+ */
    ${defaultPlaceHolderStyles(color)}
    ${styles}
  }
  :-moz-placeholder { /* Firefox 18- */
    ${defaultPlaceHolderStyles(color)}
    ${styles}
  }
`)
