import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { mq, vw, grid, getP25StaticDesktop } from '@/styles'
import { Figure } from '@/components'

const logoImagotypeGif = () => ({
  type: 'svg' as const,
  src: '/images/logo--imagotipo.gif',
  alt: 'Sablos logo',
  size: { width: 592.5, height: 592.9 }
})


const Button = styled.button`
  border-radius: 50%;
  grid-column: 1 / span 6;
  grid-row: 1 / span 1;
  height: ${vw(40, 'mobile')};
  margin-bottom: ${vw(21.5, 'mobile')};
  transform: translateY(-${vw(21.5 + 40, 'mobile')});
  width: ${vw(40, 'mobile')};

  ${mq.greaterThan('tablet')`
    display: none;
    pointer-events: none;
    visibility: hidden;
  `}
`

const MainStyled = styled(motion.main)`
  align-items: flex-end;
  display: flex;
  grid-column: 1 / span 6;
  grid-row: 2 / span 1;
  margin-bottom: ${vw(42.5, 'mobile')};
  transition: 300ms opacity ease-out;
  z-index: 2;

  /* @media only screen and (max-width: 500px) and (max-width: 767px) {
    color: red;
  } */

  /* ${mq.lessThan('tablet')`
    display: none;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
  `} */

  ${mq.greaterThan('tablet')`
    display: block;
    grid-column: 5 / span 8;
    height: ${vw(140, 'desktop')};
    margin-bottom: auto;
    margin-left: auto;
    margin-right: ${vw(grid.tablet.columns.width + grid.tablet.columns.gutter.width * 0.68, 'tablet')};
    width: 410px;
  `}

  ${mq.greaterThan('desktop-xs')`
    margin-right: ${vw(grid.desktop.columns.width + grid.desktop.columns.gutter.width * 0.68, 'desktop')};
  `}

  ${mq.greaterThan('desktop')`
    height: 140px;
  `}

  > div {
    ${mq.greaterThan('tablet')`
      border-top: 1px solid white;
    `}
  }

  p {
    display: grid;
    ${getP25StaticDesktop}
    padding: 0 ${vw(grid.mobile.columns.width, 'mobile')};

    ${mq.greaterThan('tablet')`
      padding: ${vw(30, 'desktop')} ${vw(grid.tablet.columns.width/2, 'desktop')} ${vw(30, 'desktop')} 0;
    `}

    ${mq.greaterThan('desktop-xs')`
      padding: ${vw(30, 'desktop')} ${vw(grid.desktop.columns.width/2, 'desktop')} ${vw(30, 'desktop')} 0;
    `}

    ${mq.greaterThan('desktop')`
      padding: 30px ${grid.desktop.columns.width/2}px 30px 0;
    `}

    span {
      display: block;
      grid-row: 1 / span 1;
      grid-column: 1 / span 1;

      ${mq.greaterThan('tablet')`
        max-width: 360px;
      `}
    }
  }
`

const variants = (firstTime = false) => ({
  initial: {
    opacity: 0,
    transition: { duration: .3 }
  },
  animate: {
    opacity: 1,
    transition: { delay: firstTime ? 4 : .4, duration: firstTime ? .3 : .5 }
  },
  exit: {
    opacity: 0,
    transition: { duration: .35 }
  }
})

export const mainText = [
  'We talk to the world\'s most inspiring people about the places they love in the cities they live.',
  'Our launch issue is coming in to land! Secure your magazine subscription now'
]

let firstTime = true
export const Main = () => {
  const [textIndex, setTextIndex] = useState(0)

  useEffect(() => {

    const interval = setInterval(() => {
      setTextIndex(prevTextIndex => prevTextIndex === (mainText.length - 1) ? 0 : (prevTextIndex + 1))
      if(firstTime) firstTime = false
    }, firstTime ? 9300 : 6000)

    return () => { clearInterval(interval) }
  }, [setTextIndex])

  return (
    <MainStyled initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 4, duration: .3 } }}>
      <div>
        <motion.p>
          <Button>
            <Figure media={logoImagotypeGif()} fill={false} />
          </Button>
          <AnimatePresence>
            {mainText.map((data, idx) => (
              <motion.span key={idx} variants={variants(firstTime)} initial='initial' animate={textIndex === idx ? 'animate' : 'exit'} exit='exit'>
                {data}
              </motion.span>
            ))}
          </AnimatePresence>
        </motion.p>
      </div>
    </MainStyled>
  )
}
