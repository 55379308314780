import { memo, useState, useEffect } from 'react'
import type { FC } from 'react'
import styled, { css } from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import { useModal } from '@/hooks'
import { MODALS } from '@/context'
import { mq, vw, grid, getP25StaticDesktop } from '@/styles'
import { Modal } from './Modal'
import { mainText } from '@/components'
// import { useTranslator } from '@/hooks'

type ModalProps = {}

const P = styled.p`
  display: grid;
  ${getP25StaticDesktop}
  padding: 0 ${vw(grid.mobile.columns.width + grid.mobile.columns.gutter.width/2, 'mobile')};

  span {
    display: block;
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
  }
`

const dialogStyles = css`
  align-self: flex-end;
  background-color: #1D1D1B;
  bottom: 0;
  height: 88vh;
  height: 88dvh;
  margin: 0;
  max-height: 95vh;
  top: auto;
  width: 100vw;
  z-index: 10;

  ${mq.greaterThan('tablet')`
    display: none;
  `}

  .modal__body {
    padding-left: 0;
    padding-right: 0;
    padding-top: ${vw(90, 'mobile')};
  }

  > header {
    border: 0;
    position: absolute;
    top: 0;

    button {
      margin-top: ${vw(48.96, 'mobile')};
      margin-right: ${vw(grid.mobile.columns.gutter.width, 'mobile')};
    }

    figure,
    button {
      height: ${vw(27.08, 'mobile')};
      width: ${vw(27.08, 'mobile')};
    }
  }
`

const dialogVariants = (isActive: boolean) => ({
  initial: { y: '20%', opacity: 0 },
  animate: { y: isActive ? 0 : '20%', opacity: isActive ? 1 : 0 },
  exit: { y: '20%', opacity: 0 },
  transition: { duration: .3 }
})

const mainTextVariants = {
  initial: {
    opacity: 0,
    transition: { duration: .3 }
  },
  animate: {
    opacity: 1,
    transition: { delay: 0, duration: .5 }
  },
  exit: {
    opacity: 0,
    transition: { duration: .35 }
  }
}

export const ModalLandingText: FC<ModalProps> = memo(() => {
  const { isActive, toggle } = useModal(MODALS.LANDING)

  const [textIndex, setTextIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex(prevTextIndex => prevTextIndex === (mainText.length - 1) ? 0 : (prevTextIndex + 1))
    }, 6000)

    return () => { clearInterval(interval) }
  }, [setTextIndex])

  return isActive && (
    <Modal isActive={isActive} close={toggle} xAlign='center' size='auto' variants={dialogVariants} dialogStyles={dialogStyles}>
      <AnimatePresence>
        <P>
          {mainText.map((data, idx) => (
            <motion.span key={idx} variants={mainTextVariants} initial='initial' animate={textIndex === idx ? 'animate' : 'exit'} exit='exit'>
              {data}
            </motion.span>
          ))}
        </P>
      </AnimatePresence>
    </Modal>
  )
})

