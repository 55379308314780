import { serialize } from 'cookie'
import type { NextApiResponse } from 'next'
import type { CookieSerializeOptions } from 'cookie'

//dev hardcoded
const PROD = false
const isDev = true

// interface CookieSerializeOptions {
// 	domain?: string | undefined;
// 	encode?(value: string): string;
// 	expires?: Date | undefined;
// 	httpOnly?: boolean | undefined;
// 	maxAge?: number | undefined;
// 	path?: string | undefined;
// 	sameSite?: true | false | 'lax' | 'strict' | 'none' | undefined;
// 	secure?: boolean | undefined;
// }

export const cookie = {
  set: (name, value, days = 365, seconds = null, path = '/') => {
    if (typeof window === 'undefined') return

    const date = new Date()
    let expires = ''

    if(seconds) {
      date.setTime(date.getTime() + (seconds * 1000))
    } else if (days) {
      date.setTime(date.getTime() + (days*24*60*60*1000))
    }
    expires = `; expires=${date.toUTCString()}`
    document.cookie = `${name}=${(value || '')}${expires}; path=${path}; ${PROD ? 'secure;' : ''}`
  },
  setObj: ({ name, value, days = 365, seconds = null, path = '/' }) => {
    if (typeof window === 'undefined') return

    const date = new Date()
    let expires = ''

    if(seconds) {
      date.setTime(date.getTime() + (seconds * 1000))
    } else if (days) {
      date.setTime(date.getTime() + (days*24*60*60*1000))
    }
    expires = `; expires=${date.toUTCString()}`
    document.cookie = `${name}=${(value || '')}${expires}; path=${path}; domain=${isDev ? 'localhost' : '.sablos.com'}; ${PROD ? 'secure;' : ''}`
  },
  setHeader: (res: NextApiResponse, name: string, value: string, options: CookieSerializeOptions = {}) => {
    res.setHeader('Set-Cookie', serialize(name, value, {...options, domain: !isDev ? '.sablos.com' : 'localhost'}))
  },
  get: (name) => {
    if (typeof window === 'undefined' || typeof document === 'undefined') return null

    const nameEQ         = `${name}=`
    const arrayOfCookies = document?.cookie.split(';')

    for(let rawCookie of arrayOfCookies) {
      let cookie = rawCookie
      while (cookie.charAt(0) == ' ') cookie = cookie.substring(1, cookie.length)
      if (cookie.indexOf(nameEQ) == 0) return  cookie.substring(nameEQ.length, cookie.length)
    }

    return null
  },
  removeCookie: (name) => {
    if (typeof window !== 'undefined') {
      if(isDev) {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/; domain=localhost`
        return
      }
      // STAGING
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/; domain=staging.sablos.com`
      // PRODUCTION
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/; domain=sablos.com`
      // ALL
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/; domain=.sablos.com`
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=/;`
      // Max-Age=-1; domain=
    }
  }
}
