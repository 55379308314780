// src:- https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/

export const downloadPDF = async (blob, name = 'sablos_file') => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  var newBlob = new Blob([blob], {type: 'application/pdf'})

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  //@ts-ignore
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //@ts-ignore
    window.navigator.msSaveOrOpenBlob(newBlob)
    return
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob)
  const link = document.createElement('a')
  link.href = data
  link.download=`${name}.pdf`
  link.click()
  setTimeout(function(){
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data)
  }, 100)
}

