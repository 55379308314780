import type { AppProps, NextWebVitalsMetric } from 'next/app'
import Script from 'next/script'
import { ThemeProvider } from 'styled-components'
import { TranslationsProvider } from '@/context'
import { ModalsProvider } from '@/context'
import { theme, Reset } from '@/styles'
import { Layout } from '../components/layout/Layout'
import 'flickity/css/flickity.css'

interface iProps {
  pageProps: any
  // apollo: ApolloClient<NormalizedCacheObject>
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  if (!metric.label) return
  const { label, name, startTime, value } = metric

  if (label === 'web-vital' && name === 'LCP') {
    // The metric object ({ id, name, startTime, value, label }) is logged to the console
    console.info(`${label} ${name} | startTime: ${startTime} - value: ${value}`)
  }
}

const MyApp = ({ Component, pageProps: { session, ...pageProps } }: AppProps & iProps) => {
  const { translations } = pageProps

  return (
    <>
      <ModalsProvider>
        <ThemeProvider theme={theme}>
          <Reset />
          <TranslationsProvider localization={translations}>
            <Layout >
              <Component {...pageProps} />
              <Script src='https://js.createsend1.com/javascript/copypastesubscribeformlogic.js' strategy='lazyOnload' />
              <Script src='https://www.googletagmanager.com/gtag/js?id=G-CZ91Q81LWX' strategy='lazyOnload' />
              <Script strategy='lazyOnload' id='gtm-datalayer'>
                {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', 'G-CZ91Q81LWX');
                `}
              </Script>
            </Layout>
          </TranslationsProvider>
        </ThemeProvider>
      </ModalsProvider>
    </>
  )
}

//@ts-ignore
export default MyApp
