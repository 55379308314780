export const addToCart = async (cartItem: any, item: any, addItem: any, updateItem: any, removeItem: any, onErrorHandler: any) => {
  try {
    if (cartItem) {
      if (item.quantity === 0) {
        await removeItem({ id: cartItem.id })
      } else {
        await updateItem({ id: cartItem.id, ...item })
      }
    } else if (item.quantity !== 0) {
      await addItem(item)
    }
  } catch (err) {
    console.log(err)
    onErrorHandler('Hubo un problema intentando añadir este producto. Por favor inténtelo de nuevo.')
    return { error: true }
  }
}

export const getCartUnits = (items) => {
  const units = {}
  for(const {variant_id, quantity} of items) {
    units[`${variant_id}`] = quantity
  }

  return units
}
