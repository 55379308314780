import styled from 'styled-components'
import { mq, vw, parsePlaceHolder } from '@/styles'

const Form = styled.form`
  ${mq.lessThan('tablet')`
    margin-top: ${vw(15, 'mobile')};
  `}

  .link-form-mobile {
    ${mq.greaterThan('tablet')`
      display: none !important;
      pointer-events: none;
      visibility: hidden;
    `}
  }

  button[type='submit'] {
    flex-shrink: 0;
    white-space: nowrap;

    ${mq.greaterThan('tablet')`
      margin-left: auto;
      width: auto;
    `}
  }

  input,
  button[type='submit'] {
    display: none !important;
    pointer-events: none;
    visibility: hidden;

    ${mq.greaterThan('tablet')`
      display: inline-block !important;
      pointer-events: auto;
      visibility: visible;
    `}
  }

  input {
    color: ${({ theme }) => theme.colors.white};
    flex-shrink: 1;
    min-width: 50%;

    ${mq.greaterThan('tablet')`
      padding: 0 ${vw(30, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      padding: 0 30px;
    `}

    ${({ theme }) => parsePlaceHolder(`
      color: ${theme.colors.white};
    `)}
  }

  .desktop-md-only {
    ${mq.lessThan('desktop-md')`
      display: none;
    `}
  }
`

export const FormNewsletter = () => {
  return (
    <Form className='js-cm-form' id='subForm' action='https://www.createsend.com/t/subscribeerror?description=' method='post' data-id='2BE4EF332AA2E32596E38B640E905619784B8ACAFF24AB66B80725BA69C3E9599432C80B7537E40C3F6B95BD4CC53EA777AEE47D1661EE9537E44A306C20695B'>
      <input type='email' name='cm-jdlytrk-jdlytrk' placeholder='Email address' autoComplete='Email' aria-label='Email' className='js-cm-email-input qa-input-email' id='fieldEmail' maxLength={70} required ></input>
      <button type='submit'><span>Join <span className='desktop-md-only'>the </span>mailing list</span></button>
      <a className='link-form-mobile' href='https://confirmsubscription.com/h/t/38368157AEDB68BA'><span>Join the mailing list</span></a>
    </Form>
  )
}
