import { css } from 'styled-components'
import { generateMedia, pxToRem } from 'styled-media-query'
import { themeProject } from './themeConfig'

const breakpoints = {
  'iphone6': '375px',
  'mobile': '414px',
  'phablet': '480px',
  'nexus7': '603px',
  'tablet': '768px',
  'desktop-xs': '992px',
  'container': '1170px',
  'desktop-md': '1200px',
  'desktop': '1440px',
  'large-screen': '1900px'
}

export const mq = generateMedia(pxToRem(breakpoints, themeProject.fonts.size))

export const vw = (size, breakpoint, unit = 'vw') => {
  const screenWidth = breakpoints[breakpoint] ? breakpoints[breakpoint] : breakpoint
  return `${(100 * parseInt(size) / parseInt(screenWidth))}${unit}`
}

export const vh = (size, breakpoint) => {
  let screenWidth = breakpoint
  return `${(100 * parseInt(size) / parseInt(screenWidth))}vh`
}

export const rem = (value) => {
  const unitlessValue = parseInt(value)
  if (unitlessValue == 0) return unitlessValue
  return `${unitlessValue / themeProject.fonts.size}rem`
}

export const getEasing = (duration = 300) => (
  {
    duration,
    ease: [.25, .1, .25, 1]
  }
)

export const getTransition = (duration = .3) => ({
  duration,
  type: 'tween',
  ease: [.25, .1, .25, 1]
})

export type Col = 'auto' | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
interface iColumns {
  gutter: {
    outside: boolean,
    width: number
  },
  number: number,
  width: number
}

export interface iGrid {
  readonly mobile?: {
    maxWidth: number,
    columns: iColumns
  },
  readonly tablet?: {
    maxWidth: number,
    columns: iColumns
  },
  readonly desktop?: {
    maxWidth: number,
    columns: iColumns
  },
  readonly withoutGutter?: boolean,
}

export const grid:iGrid = {
  mobile: {
    maxWidth: 414,
    columns: {
      gutter: { outside: true, width: 17 },
      number: 6,
      width: 52
    }
  },
  tablet: {
    maxWidth: 768,
    columns: {
      gutter: { outside: true, width: 12 },
      number: 12,
      width: 52
    }
  },
  desktop: {
    maxWidth: 1440,
    columns: {
      gutter: { outside: true, width: 50 },
      number: 12,
      width: 70
    }
  },
  withoutGutter: false
}


export const designGrid = ({ mobile = grid.mobile, tablet = grid.tablet, desktop = grid.desktop, withoutGutter = grid.withoutGutter }:iGrid) => css`
  display: grid;
  grid-template-columns: ${`repeat(${mobile.columns.number}, ${vw(withoutGutter ? mobile.columns.width + mobile.columns.gutter.width : mobile.columns.width, mobile.maxWidth, '%')})`};
  grid-column-gap: ${withoutGutter ? 'unset' : `${vw(mobile.columns.gutter.width, mobile.maxWidth, '%')}`};
  grid-row-gap: 0;
  justify-content: center;
  width: 100%;

  ${mq.greaterThan<iGrid>('tablet')`
    grid-column-gap: ${withoutGutter ? 'unset' : `${vw(tablet.columns.gutter.width, tablet.maxWidth, '%')}`};
    grid-template-columns: ${`repeat(${tablet.columns.number}, ${vw(withoutGutter ? tablet.columns.width + tablet.columns.gutter.width : tablet.columns.width, tablet.maxWidth, '%')})`};
    padding: 0;
  `}

  ${mq.greaterThan<iGrid>('desktop')`
    grid-column-gap: ${withoutGutter ? 'unset' : `${vw(desktop.columns.gutter.width, desktop.maxWidth, '%')}`};
    grid-template-columns: ${`repeat(${desktop.columns.number}, ${vw(withoutGutter ? desktop.columns.width + desktop.columns.gutter.width : desktop.columns.width, desktop.maxWidth, '%')})`};
  `}
`
