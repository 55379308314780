import { FC, useMemo } from 'react'
import Head   from 'next/head'
import { useRouter } from 'next/router'
import { home } from './jsonld'
import { getLang } from '../../utils'
// NOTE: <title> and <meta> elements need to be contained as direct children of the <Head> element,
// or wrapped into maximum one level of <React.Fragment>, otherwise
// the metatags won't be correctly picked up on clientside navigation.
// NOTE:- Segun parece tampoco acepta `Fragments` que son hermanos. Solo pilla el primero.
const hostname = process.env.DY_CLIENT_URL
const generateJson = {
  'home': (data) => home(data)
}

interface IHead {
  readonly seo?: {
    title: string,
    description: string,
    video?: {
      content_type: string,
      default: string,
      size: {
        height: number,
        width: number
      }
    },
    [key: string]: any
  },
  readonly jsonld?: {
    data: any,
    type?: string,
    [key: string]: any
  },
  pageType?: keyof typeof generateJson,
  slug?: any
}

const defaultOgImage = {
  default: 'https://sablos.com/images/og_cover.jpg',
  content_type: 'image/jpg',
  alt: 'Sablos',
  size: {
    height: 627,
    width: 1200
  }
}

let hardcodedUrl = ''
const getBaseUrl = (locale, hostName) => {
  switch (locale) {
    case 'es-ES':
      hardcodedUrl = hostName +'.es'
      break
    case 'es-CN':
      hardcodedUrl = 'canarias.' +hostName + '.es'
      break
    case 'es-MD':
      hardcodedUrl = 'madrid.' +hostName + '.es'
      break
    case 'es-CT':
      hardcodedUrl ='cat.' +hostName + '.es'
      break
    case 'fr-FR':
      hardcodedUrl = hostName + '.fr'
      break
    case 'de-DE':
      hardcodedUrl = hostName + '.de'
      break
    case 'en-GB':
      hardcodedUrl = hostName + '.co.uk'
      break
    default:
      hardcodedUrl = hostName + 'eu'
      break
  }
}

const generateCanonicalAndAlternates = ({ locale, pageType, slug }) => {
  const localeSlug = (slug !== 'search') ? (typeof slug === 'string') ? slug : slug[locale] : ''
  //dev hardcoded
  let hardcoded = 'sablos'
  getBaseUrl(locale, hardcoded)
  const href = `https://${hardcodedUrl}/${pageType !== 'home' ? pageType === 'contents'? 'page': pageType : ''}${localeSlug ? `/${localeSlug}` : ''}`
  const hrefLang = locale
  return <link key={'canonical'} rel='canonical' href={href} hrefLang={hrefLang} />

}

export const HeadTag:FC<IHead> = ({ seo, jsonld = null, slug = null, pageType = null }) => {
  const { locale:localeRouter, query } = useRouter()
  const locale = getLang(localeRouter ?? 'en')
  const image = defaultOgImage
  // const image = pageType === 'product' ? data.header.image_data : defaultOgImage
  const OgImages = [
    <meta property='og:image' key='og:image' content={image.default} />,
    <meta property='og:image:alt' key='og:image:alt' content={image.alt} />,
    <meta property='og:image:type' key='og:image:type' content={image.content_type} />,
    <meta property='og:image:secure_url' key='og:image:secure_url' content={image.default} />,
    <meta property='og:image:height' key='og:image:height' content={`${image.size.height}`} />,
    <meta property='og:image:width' key='og:image:width' content={`${image.size.width}`} />,
  ]

  const OgBase = [
    <meta property='og:description' key='og:description' content={seo.description} />,
    <meta property='og:locale' key='og:locale' content={`${locale}`} />,
    <meta property='og:site_name' key='og:site_name' content='Sablos' />,
    <meta property='og:title' key='og:title' content={seo.title} />,
    <meta property='og:type' key='og:type' content='website' />,
    <meta property='og:url' key='og:url' content={hostname} />,
  ]

  const OgVideo = seo.video ? [
    <meta property='og:video:type' key='og:video:type' content={seo.video.content_type} />,
    <meta property='og:video:secure_url' key='og:video:secure_url' content={seo.video.default} />,
    <meta property='og:video:height' key='og:video:height' content={`${seo.video.size.height}`} />,
    <meta property='og:video:width' key='og:video:width' content={`${seo.video.size.width}`} />,
  ] : []

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const CanonicalAndAlternates = useMemo(() => slug || slug === '' ? generateCanonicalAndAlternates({ locale, pageType, slug }) : [], [query])

  return (
    <Head>
      <title>{seo.title}</title>
      <meta name='description' content={seo.description} />
      {OgBase}
      {OgImages}
      {OgVideo}
      {jsonld && <script type='application/ld+json' dangerouslySetInnerHTML={{ __html: generateJson[jsonld.type ?? pageType](jsonld.data, query.region, locale) }} />}
      {CanonicalAndAlternates}
    </Head>
  )
}
