export const subscribe = async ({ body = {} }): Promise<boolean | 'duplicated'> => {
  const response = await fetch('/api/active-campaign/newsletter', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      contact: body,
    })
  })

  const data = await response.json()
  return data.data.contactList ? true : data.data.duplicate ? 'duplicated' : false
}
