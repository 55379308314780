import type { FC } from 'react'
import styled from 'styled-components'

import { designGrid, grid, iGrid } from './'
import { Media, MediaContextProvider } from '../components'

const SusyGrid = styled.div<iGrid>`
  ${designGrid({})}
  bottom: 0;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  user-select: none;
  z-index: 9999;
`

type typeColumnProps = {
  readonly columnIndex: number
}

const Column = styled.span<typeColumnProps>`
  background-color: rgba(76, 0, 255, 0.01);
  border: 1px solid rgba(85, 0, 255, 0.08);
  grid-area: ${({ columnIndex }) => `${1}/${columnIndex + 1}/${2}/${columnIndex + 1}`};
`

const getColumns = (columns:number, className:string) => {
  const array = []
  for (let idx = 0; idx < columns; idx++) {
    array.push(<Column key={idx} columnIndex={idx} className={className} />)
  }
  return array
}

export const Susy:FC = () => {
  return (
    <SusyGrid {...grid} className='susy'>
      <MediaContextProvider>
        <Media at='mobile'>
          {(mediaClassNames) => { return getColumns(6, mediaClassNames)}}
        </Media>
        <Media greaterThan='mobile'>
          {(mediaClassNames) => { return getColumns(12, mediaClassNames)}}
        </Media>
      </MediaContextProvider>
    </SusyGrid>
  )
}
