import { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

export const SLIDE_TIME = 5000
export const useSlider = (maxSize, always = false) => {
  const [currentSlide, setSlide] = useState(0)
  const TIMEOUT_ID = useRef(null)

  const [ref, inView] = useInView({
    rootMargin: '0px',
    threshold: 0,
  })

  const nSlides = useMemo(() => maxSize, [maxSize])

  const tick = useCallback((current) => {
    TIMEOUT_ID.current && clearTimeout(TIMEOUT_ID.current)

    const newSlideIndex = current + 1
    const nextSlide =  newSlideIndex < 0 ? nSlides - 1 : newSlideIndex % nSlides

    TIMEOUT_ID.current = setTimeout(() => tick(nextSlide), SLIDE_TIME)

    setSlide(nextSlide)
  }, [nSlides])

  const startAnimation = useCallback(() => {
    tick(currentSlide)
  }, [currentSlide, tick])

  const nextSlide = useCallback(() => {
    tick(currentSlide)
  }, [currentSlide, tick])

  const prevSlide = useCallback(() => {
    tick(currentSlide - 2)
  }, [currentSlide, tick])

  useEffect(() => {
    if (inView || always) {
      TIMEOUT_ID.current && clearTimeout(TIMEOUT_ID.current)
      TIMEOUT_ID.current = setTimeout(() => startAnimation(), SLIDE_TIME)
    } else {
      TIMEOUT_ID.current && clearTimeout(TIMEOUT_ID.current)
      TIMEOUT_ID.current = null
    }

    return () => {
      TIMEOUT_ID.current && clearTimeout(TIMEOUT_ID.current)
    }
  }, [inView])

  return { currentSlide, triggerRef: ref, nextSlide, prevSlide, setSlide, startAnimation }
}
