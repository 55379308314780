import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Figure, Link } from '@/components'
import { mq, vw, grid, designGrid, getP29SloganStaticDesktop } from '@/styles'

const Navbar = styled.nav`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 1 / span 6;
  grid-row: 1 / span 1;
  padding-top: ${vw(78, 'mobile')};
  z-index: 10;

  ${mq.greaterThan('tablet')`
    ${designGrid({})}
    flex-direction: row;
    grid-column: 1 / span 12;
    height: ${vw(171, 'desktop')};
    justify-content: space-between;
    padding-top: 0;
  `}

  ${mq.greaterThan('desktop')`
    height: 171px;
  `}

  div {
    ${mq.greaterThan('tablet')`
      grid-column: 1 / span 5;
      margin-left: ${vw(grid.tablet.columns.width/2, 'desktop')};
    `}

    ${mq.greaterThan('desktop-xs')`
      margin-left: ${vw(grid.desktop.columns.width/2, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      margin-left: ${grid.desktop.columns.width/2}px;
    `}
  }

  figure {
    height: ${vw(79, 'mobile')};
    margin-bottom: ${vw(26, 'mobile')};
    width: ${vw(292.22, 'mobile')};

    ${mq.greaterThan('tablet')`
      height: ${vw(93, 'desktop')};
      margin-bottom: 0;
      width: ${vw(344, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      height: 93px;
      width: 344px;
    `}
  }

  p {
    ${getP29SloganStaticDesktop}
    font-weight: 600;
    align-items: center;
    display: flex;
    margin: 0 auto ${vw(50, 'mobile')};

    ${mq.greaterThan('tablet')`
      display: flex;
      grid-column: 6 / span 7;
      grid-row: 1 / span 1;
      justify-content: flex-start;
      margin: 0;
      margin-left: auto;
      margin-right: ${vw(grid.tablet.columns.width + grid.tablet.columns.gutter.width * 0.68, 'tablet')};
      padding: 0;
      width: 410px;
    `}

    ${mq.greaterThan('desktop-xs')`
      margin-right: ${vw(grid.desktop.columns.width + grid.desktop.columns.gutter.width * 0.68, 'desktop')};
    `}


    span {
      ${mq.greaterThan('tablet')`
        display: inline-block;
        margin-top: 4px;
      `}
    }
  }

  .lottie-container,
  button {
    border-radius: 50%;
    grid-column: 12 / span 1;
    grid-row: 1 / span 1;
    height: ${vw(45, 'mobile')};
    width: ${vw(45, 'mobile')};

    ${mq.lessThan('tablet')`
      display: none;
    `}

    ${mq.greaterThan('tablet')`
      height: ${vw(grid.tablet.columns.width, 'tablet')};
      margin: 0;
      margin-right: -0.4vw;
      pointer-events: none;
      width: 74px;
      width: ${vw(grid.tablet.columns.width, 'tablet')};
    `}

    ${mq.greaterThan('desktop-xs')`
      height: ${vw(grid.desktop.columns.width, 'desktop')};
      width: ${vw(grid.desktop.columns.width, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      height: 74px;
      width: 74px;
    `}

    figure {
      height: 100%;
      width: 100%;
    }
  }
`

const logoSvg = () => ({
  type: 'svg' as const,
  src: '/images/svg/logo.svg',
  alt: 'Sablos logo',
  size: { width: 1646.11, height: 447.8 }
})

const logoImagotypeGif = () => ({
  type: 'svg' as const,
  src: '/images/logo--imagotipo.gif',
  alt: 'Sablos logo',
  size: { width: 592.5, height: 592.9 }
})

export const Nav = () => {
  return (
    <Navbar>
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 2, duration: .3 }}}>
        <Link href='/'>
          <Figure media={logoSvg()} fill={false} lazy={false} />
        </Link>
      </motion.div>
      <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 3, duration: .3 } }}>
        <span>PEOPLE and their PLACES</span>
      </motion.p>
      <motion.button initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 3, duration: .3 } }}>
        <Figure media={logoImagotypeGif()} fill={false} />
      </motion.button>
    </Navbar>
  )
}
