import { css } from 'styled-components'
import { mq } from '.'

// 1rem mobile -> 15px, 1rem desktop -> 18px
const rem = {
  baseMobile: 15,
  base: 18,
  get: (value, factor) => `${value / factor}rem`
}

export const getP29Slogan = css`
  font-size: ${rem.get(16.5, rem.baseMobile)};
  line-height: ${rem.get(23, rem.baseMobile)};
  font-weight: 500;

  ${mq.greaterThan('tablet')`
    font-size: ${rem.get(32, rem.base)};
    line-height: ${rem.get(35, rem.base)};
  `}
`

export const getP29SloganStaticDesktop = css`
  font-size: ${rem.get(16.5, rem.baseMobile)};
  line-height: ${rem.get(23, rem.baseMobile)};
  font-weight: 500;

  ${mq.greaterThan('tablet')`
    font-size: 32px;
    line-height: 35px;
  `}
`

export const getP25 = css`
  font-size: ${rem.get(25, rem.baseMobile)};
  line-height: ${rem.get(28, rem.baseMobile)};
  font-weight: 500;

  ${mq.greaterThan('tablet')`
    font-weight: normal;
    font-size: ${rem.get(25, rem.base)};
    line-height: ${rem.get(28, rem.base)};
  `}
`

export const getP25StaticDesktop = css`
  font-size: ${rem.get(25, rem.baseMobile)};
  line-height: ${rem.get(28, rem.baseMobile)};
  font-weight: 500;

  ${mq.greaterThan('tablet')`
    font-weight: normal;
    font-size: 25px;
    line-height: 28px;
  `}
`

export const getP16Input = css`
  font-size: ${rem.get(17, rem.baseMobile)};
  line-height: ${rem.get(17, rem.baseMobile)};
  font-weight: normal;

  ${mq.greaterThan('tablet')`
    font-size: ${rem.get(16, rem.base)};
    line-height: ${rem.get(17, rem.base)};
  `}
`

export const getP16InputStaticDesktop = css`
  font-size: ${rem.get(17, rem.baseMobile)};
  line-height: ${rem.get(17, rem.baseMobile)};
  font-weight: normal;

  ${mq.greaterThan('tablet')`
    font-size: 16px;
    line-height: 17px;
  `}
`

// Landing button link
export const getP16EarlyBird = css`
  font-size: ${rem.get(17, rem.baseMobile)};
  line-height: ${rem.get(17, rem.baseMobile)};
  font-weight: normal;

  ${mq.greaterThan('tablet')`
    font-weight: 500;
    font-size: ${rem.get(14, rem.base)};
    line-height: ${rem.get(17, rem.base)};
  `}
`

export const getP16EarlyBirdStaticDesktop = css`
  font-size: ${rem.get(17, rem.baseMobile)};
  line-height: ${rem.get(17, rem.baseMobile)};
  font-weight: normal;

  ${mq.greaterThan('tablet')`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  `}
`
