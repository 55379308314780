import { themeProject } from '../../styles'

export const Favicon = () => {
  return (
    <>
      <link rel='icon' type='image/x-icon' sizes='16x16'    href='/favicon/favicon.ico' />
      <link rel='icon' type='image/png'    sizes='16x16'    href='/favicon/favicon-16x16.png' />
      <link rel='icon' type='image/png'    sizes='32x32'    href='/favicon/favicon-32x32.png' />
      <link rel='apple-touch-icon'         sizes='180x180'  href='/favicon/apple-icon-180x180.png' />
      <link rel='icon' type='image/png'    sizes='192x192'  href='/favicon/android-icon-192x192.png'/>
      <link rel='icon' type='image/png'    sizes='512x512'  href='/favicon/apple-icon-precomposed.png' />
      <link rel='manifest'  href='/favicon/site.webmanifest'/>
      <link rel='mask-icon' href='/favicon/safari-pinned-tab.svg' color={themeProject.colors.black} />
      <meta name='apple-mobile-web-app-capable'          content='yes' />
      <meta name='apple-mobile-web-app-status-bar-style' content='black' />
      <meta name='apple-mobile-web-app-title'            content='Sablos' />
      <meta name='application-name'                      content='Sablos' />
      <meta name='theme-color'                           content={themeProject.colors.black} />
      <meta name='msapplication-navbutton-color'         content={themeProject.colors.black} />
      <meta name='msapplication-TileColor'               content={themeProject.colors.black} />
    </>
  )
}
