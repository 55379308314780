import { useEffect, useState, memo } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'
import { Figure } from '@/components'
import { useMount } from '@/hooks'
import { designGrid, mq, getTransition } from '@/styles'

const HeaderStyled = styled.header`
  background: rgba(0, 0, 0, .4);
  bottom: 0;
  display: flex;
  height: 100vh;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  width: 100vw;
  z-index: 0;

  figure {
    height: 100vh;
    opacity: 1;
    width: 100vw;
  }

  img {
    object-fit: cover;
  }

  &:after {
    background: rgba(0, 0, 0, .2);
    display: block;
    content: '';
    height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
  }
`

const Ul = styled.ul`
  ${designGrid({ withoutGutter: true })}
  height: 100vh;
  width: 100vw;
  z-index: 0;
`

const Li = styled(motion.li)`
  grid-column: 1 / span 6;
  grid-row: 1 / span 1;
  height: 100vh;
  width: 100vw;

  ${mq.greaterThan('tablet')`
    grid-column: 1 / span 12;
  `}
`

const transition = getTransition(.9)
const variants = {
  initial: {
    opacity: 0,
    transition
  },
  animate: {
    opacity: 1,
    transition
  },
  exit: {
    opacity: 0,
    transition
  }
}

const getImagesIds = (length = 27) => {
  const orderedArr = Array.from({ length }, (_, i) => i + 1)
  const shuffledArray = orderedArr.sort(() => 0.5 - Math.random())
  const arrLength = 7
  const slicedArray = shuffledArray.slice(0, arrLength)
  return slicedArray
}

const images = getImagesIds()
const getMedia = (idx) => {
  const id = images[idx]
  return {
    type: 'image' as const,
    alt: `Header image n${id}`,
    default: {
      src: `/images/desktop/reducidas/${id}.jpg`,
      size: {
        width: 1081,
        height: 608
      }
    },
    mobile: {
      src: `/images/mobile/reducidas/${id}.jpg`,
      size: {
        width: 343,
        height: 608
      }
    }
  }
}

export const Header = memo(() => {
  const [arrayIndex, setArrayIndex] = useState(0)
  const isMounted = useMount()

  useEffect(() => {
    const interval = setInterval(() => {
      setArrayIndex(prevSlide => {
        return prevSlide === 6 ? 1 : (prevSlide + 1)
      })
    }, 5000)

    return () => { clearInterval(interval) }
  }, [setArrayIndex])

  return (
    <HeaderStyled>
      <Ul>
        <AnimatePresence>
          {isMounted &&
            <Li key={`slide-${arrayIndex}`} variants={variants} initial='initial' animate={'animate'} exit='exit' >
              <Figure media={getMedia(arrayIndex)} lazy={false} quality={.75}/>
            </Li>
          }
        </AnimatePresence>
      </Ul>
    </HeaderStyled>
  )
})
