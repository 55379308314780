import { motion } from 'framer-motion'
import styled from 'styled-components'
// import { Figure } from '@/components'
import { FormNewsletter } from './FormNewsletter'
import { mq, vw, grid, getP16EarlyBirdStaticDesktop, getP16InputStaticDesktop, hex2Rgba } from '@/styles'
// import { useModal } from '@/hooks'
// import { MODALS } from '@/context'

const FooterStyled = styled(motion.footer)`
  display: flex;
  grid-column: 1 / span 6;
  grid-row: 3 / span 1;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 ${vw(grid.mobile.columns.width, 'mobile')} ${vw(35, 'mobile')};
  position: relative;
  z-index: 700;

  ${mq.greaterThan('tablet')`
    align-items: flex-end;
    flex-wrap: wrap;
    grid-column: 5 / span 8;
    margin-left: auto;
    margin-right: ${vw(grid.tablet.columns.width + grid.tablet.columns.gutter.width * 0.68, 'tablet')};
    padding: 0 0 ${vw(75, 'desktop')};
    width: 410px;
  `}

  ${mq.greaterThan('desktop-xs')`
    margin-right: ${vw(grid.desktop.columns.width + grid.desktop.columns.gutter.width * 0.68, 'desktop')};
    padding: 0 0 ${vw(75, 'desktop')};
  `}

  ${mq.greaterThan('desktop')`
    padding: 0 0 75px;
  `}

  .desktop-only {
    ${mq.lessThan('tablet')`
      display: none;
    `}
  }

  .mobile-only {
    ${mq.greaterThan('tablet')`
      display: none;
    `}
  }

  p {
    display: none;
    ${getP16InputStaticDesktop}

    ${mq.greaterThan('tablet')`
      display: inline-block;
      margin-left: ${vw(39, 'desktop')};
      max-width: ${vw(127, 'desktop')};
    `}

    ${mq.greaterThan('desktop')`
      margin-left: 39px;
    `}
  }

  > div {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    opacity: 1;

    ${mq.lessThan('tablet')`
      margin-top: ${vw(15, 'mobile')};
    `}

    ${mq.greaterThan('tablet')`
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: ${vw(25, 'desktop')};
      width: 100%;
    `}

    ${mq.greaterThan('desktop')`
      margin-bottom: 25px;
    `}
  }

  a {
    margin-bottom: ${vw(15, 'mobile')};

    ${mq.greaterThan('tablet')`
      margin-bottom: 0;
      font-weight: normal !important;
    `}

    &:last-child {
      margin-bottom: 0 !important;

      ${mq.greaterThan('tablet')`
        margin-left: ${vw(15, 'desktop')};
      `}

      ${mq.greaterThan('desktop')`
        margin-left: 15px;
      `}
    }
  }

  a,
  button[type='submit'] {
    transition: 300ms background-color ease-out, 300ms color ease-out;

    &:hover {
      background-color: ${({ theme }) => hex2Rgba(theme.colors.white, .2)};
      color: ${({ theme }) => theme.colors.white};
    }
  }

  a,
  button[type='submit'] {
    border: 1px solid ${({ theme }) => theme.colors.white};
  }

  a,
  a.link-form-mobile {
    display: flex;
  }

  a,
  a.link-form-mobile,
  button[type='submit'] {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${vw(43/2, 'mobile')};
    color: ${({ theme }) => theme.colors.black};
    height:  ${vw(43, 'mobile')};
    justify-content: center;
    padding: 0 ${vw(30, 'mobile')};

    ${mq.greaterThan('tablet')`
      border-radius: ${43/2}px;
      height: 43px;
      padding: 0 30px;
    `}
  }

  a {
    ${getP16EarlyBirdStaticDesktop}
    white-space: nowrap;

    ${mq.lessThan('tablet')`
      width: 100%;
    `}

    ${mq.greaterThan('tablet')`
      border-radius: ${35 / 2}px;
      height: 35px;
    `}
  }

  a.link-form-mobile,
  button[type='submit'] {
    width: 100%;

    ${mq.greaterThan('tablet')`
      border-radius: 23.5px;
      height: 47px;
      width: auto;
    `}
  }

  form {
    order: 2;
    width: 100%;

    ${mq.greaterThan('tablet')`
      align-items: center;
      display: flex;
      border: 1px solid white;
      border-radius: ${56/2}px;
      height: 56px;
      margin-bottom: 0;
      padding: 0 ${4.5}px;
    `}
  }

  form,
  > div {
    ${mq.greaterThan('tablet')`
      width: 410px;
    `}
  }
`

export const Footer = () => {
  // const { toggle } = useModal(MODALS.LANDING)s

  return (
    <FooterStyled initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 5, duration: .3 } }}>
      {/* <Button onClick={toggle}>
        <Figure media={logoImagotypeGif()} fill={false} />
      </Button> */}
      <FormNewsletter />
      <div>
        <a href='https://buy.stripe.com/28ocQfbODaIJ52wfZ0'><span>Subscribe to SABLOS</span></a>
        <a href='https://buy.stripe.com/3csaI7g4T6stamQ28b'><span>Buy launch issue</span></a>
      </div>
    </FooterStyled>
  )
}
