import { useCallback, useEffect, useState } from 'react'
import lottie from 'lottie-web'

const useLottie = (name, loop = true, color = '') => {
  const [ref, setRef] = useState(null)
  const [animation, setAnimation] = useState(null)

  const register = (ref) => {
    if (ref) setRef(ref)
  }

  useEffect(() => {
    if (ref) {
      const path = `/lottie/${name}${color ? `--${color}` : ''}.json`
      const _animation = lottie.loadAnimation({
        container: ref,
        renderer: 'svg',
        loop,
        autoplay: true,
        path,
        name
      })

      setAnimation(_animation)
    }

    return () => animation && animation.destroy()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, name, color])

  return { register, animation }
}


export const Lottie = ({ isActive = true, name }) => {
  const { animation, register } = useLottie(name)

  useEffect(() => {
    if (animation && isActive) {
      animation.play()
    }

    return () => animation && animation.stop()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive])

  return (
    <div className={'lottie-container ' + name} ref={register}/>
  )
}

export const ButtonLottie = ({ isModalActive = false, name, speed = 1, onClick = null }) => {
  const { animation, register } = useLottie(name, false)
  const [isActive, setActive] = useState(true)

  useEffect(() => {
    if (animation) animation.setSpeed(speed)

    return () => { animation && animation.destroy() }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animation])

  const handleAnimation = useCallback(() => {
    animation.setDirection(isActive ? 1 : -1)
    animation.play()
    setActive((old) => !old)
  }, [setActive, animation, isActive])

  const handleClick = () => {
    handleAnimation()
    onClick && onClick()
  }

  useEffect(() => {
    if (animation && isActive !== !isModalActive) handleAnimation()
  }, [isModalActive, handleAnimation, isActive, animation])

  return (
    <button onClick={handleClick} className={`${'lottie-button-' + name}`} ref={register} name={name}/>
  )
}
