export interface ITheme {
  colors: {
    [key: string]: string;
  },
  fonts: {
    primaryFont: string,
    secondaryFont: string,
    tertiaryFont: string,
    familyCode: string,
    size: number,
    headersize: number,
    [key: string]: string | number;
  },
  ease: string
}

export const themeProject: ITheme = {
  colors: {
    'background': '#F3EFE6',
    'text': '#FFFFFF',
    'red': '#A92329',
    'darkred': '#4E1114',
    'lightred': '#D8C7C7',
    'creme': '#EFC881',
    'darkcreme': '#E8A836',
    'white': '#FFFFFF',
    'black': '#000000',
    'input_error': '#FF4E15',
    'grey': '#979797',
    'greylight': '#F7F7F7',
    'transparent': 'transparent'
  },
  fonts: {
    // eslint-disable-next-line quotes
    'primaryFont': `'founders_grotesk', sans-serif`,
    // eslint-disable-next-line quotes
    'secondaryFont': `'founders_grotesk', sans-serif`,
    // eslint-disable-next-line quotes
    'tertiaryFont': `'founders_grotesk', sans-serif`,
    // eslint-disable-next-line quotes
    'familyCode': `'monospace', monospace`,
    'size': 16,
    'lineHeight': 24 / 16,
    'headersize': 20,
    'headerLineHeight': 24 / 20
  },
  ease: 'cubic-bezier(.25, .1, .25, 1)'
}
