// PENDING improve locale on base file instead of here since its a general type
// type TypeLocale = 'en-EU' | 'es-ES' | 'es-CT' | 'es-CN' | 'es-MD' | 'en-GB' | 'fr-FR' | 'de-DE'
// type TypeLocaleCountry = 'es-ES' | 'en-GB' | 'fr-FR' | 'de-DE'

// export function getLocaleCountry(locale: TypeLocale): TypeLocaleCountry {
export function getLocaleCountry(locale:string) {
  switch (locale) {
    case 'es-CN':
    case 'es-MD':
    case 'es-CT':
      return 'es-ES'
    case 'en-EU':
      return 'en-GB'
    default:
      if(locale.split('-')?.length === 2) return locale
      return `${locale}-${locale.toUpperCase()}`
  }
}

export function getLang(str:string) {
  if (typeof str !== 'string') throw new Error('getLang parameter must be typeof "string"')
  return str.substring(2, 0)
}

export function getRegion(str:string) {
  if (typeof str !== 'string') throw new Error('getRegion parameter must be typeof "string"')
  return str.split('-')[1]
}
