import { createContext, useState } from 'react'
import type { FC, ReactNode } from 'react'

export enum MODALS {
  // ACCOUNT = 'modal_account',
  // WELCOME = 'modal_welcome',
  LANDING = 'modal_landing',
  // AUTH = 'modal_auth',
}

// type TypeSectionAuthLevel = 'initial' | 'login' | 'signup' | 'forgotPassword' | 'resetPassword'

export interface iDefaultModal { isActive: boolean, payload?: any }

// interface iModalAuthPayload {
//   payload: {
//     level: TypeSectionAuthLevel
//   }
// }

type TypeModalException = {
  // AUTH = 'modal_auth',
}

// Utility to merge two Definitions: B object properties shadowing -> A object properties
type $Merge<TA, TB> = Omit<TA, keyof TB> & TB;
// Getting the Base+Extended type of an Modal
type TypeModalById<A extends MODALS> = A extends keyof TypeModalException ? $Merge<iDefaultModal, TypeModalException[A]> : iDefaultModal


type TypeModals = {
  [key in MODALS]?: TypeModalById<key>
}
type TypeModalsState = {
  modals: TypeModals,
  setModal: (modal: { [key: string]: iDefaultModal } | ((prev: TypeModals) => TypeModals)) => void,
  someModalIsActive: boolean
}

const initialModalsState: TypeModalsState = {
  modals: {},
  setModal: () => {},
  someModalIsActive: false
  //someModalIsActive: boolean at least one modal is Active ?
}

interface iModalsProviderProps {
  children: ReactNode
}

export const ModalsContext = createContext<TypeModalsState | null>(initialModalsState)

export const ModalsProvider:FC<iModalsProviderProps> = ({ children }) => {
  const [modals, setModals] = useState<TypeModals>({})
  const [someModalIsActive, setSomeModalIsActive] = useState(false)

  const setModal = (modal) => {
    setModals(prevModals => ({...prevModals, ...modal }))
    let someActive = false
    if (modal.isActive) someActive = true
    else {
      for (const [, m] of Object.entries(modals)) {
        if (m.isActive) {
          someActive = true
          break
        }
      }
    }

    setSomeModalIsActive(someActive)
  }

  return (<ModalsContext.Provider value={{ modals, setModal, someModalIsActive }}>
    {children}
  </ModalsContext.Provider>)
}
