import { createMedia } from '@artsy/fresnel'

const MediaQueries = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    laptop: 996,
    desktop: 1200,
    screen: 1440,
  }
})

// Make styles for injection into the header of the page
export const mediaStyles = MediaQueries.createMediaStyle()
export const { Media, MediaContextProvider } = MediaQueries
