import { useCallback, useContext, useEffect, useMemo } from 'react'
import { ModalsContext } from '../context'
import type { MODALS } from '../context'

export const useModal = (id:MODALS, openOnLoad:boolean = false) => {
  const { modals, setModal } = useContext(ModalsContext)
  const { isActive, payload } = useMemo(() => modals[id] ?? { isActive: openOnLoad, payload: null }, [modals, id, openOnLoad])

  const toggle = useCallback(() => {
    setModal({ [id]: { isActive: !isActive, payload }})
  }, [id, setModal, isActive, payload])

  const updatePayload = useCallback((payload = null) => {
    setModal({ [id]: { isActive, payload }})
  }, [id, setModal, isActive])

  const handleEsc = useCallback((e:KeyboardEvent):void => {
    if(e.key === 'Escape') toggle()
  }, [toggle])

  useEffect(() =>{
    if (!modals[id]) setModal({ [id]: { isActive: openOnLoad, payload: null }})
  }, [id, modals, setModal, openOnLoad])

  useEffect(() => {
    if(isActive) window.addEventListener('keydown', handleEsc)
    document.body.style.overflow = isActive ? 'hidden' : 'initial'

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [isActive, handleEsc])

  return { isActive, toggle, payload, updatePayload }
}
