import { overwriteDefaultTheme, withGlobalStyle, themeProject, vw } from './'

export const theme = overwriteDefaultTheme(themeProject)

const extraGlobalCss = `
  html {
    overflow-x: hidden;
  }

  body {
    color: ${themeProject.colors.text};
    font-family: ${themeProject.fonts.primaryFont};

    &.slider-being-dragged {
      .slider--flickity {
        button,
        a {
          pointer-events: none !important;
        }
      }
    }
  }

  button {
    outline: 0;
    padding: 0;
    background-color: transparent;

    &:active,
    &:focus {
      outline: 0;
    }
  }

  a {
    color: unset;
    text-decoration: none;
  }

  input {
    background: transparent;
    margin-bottom: 0;
  }

  input::-webkit-search-cancel-button {
    display: none;
  }

  html, body {
    font-size: ${vw(15, 'mobile')};

    @media (min-width: 768px) {
      font-size: ${vw(18, 'desktop')};
    }
  }

  figure {
    margin-bottom: 0;
  }

  img {
    width: 100%;
  }

  .modal-as-tooltip {
    justify-content: flex-end;

    .overlay {
      opacity: .2;
    }
  }

  .slider--flickify {
    outline: none;
  }

  .flickify-viewport {
    overflow: hidden;
    position: relative;
  }
`

export const Reset = withGlobalStyle(theme, extraGlobalCss)
