import { useEffect, useState } from 'react'

interface Breakpoints {
  mobile: number,
  tablet: number,
  desktopXs: number,
  desktop: number,
  getDeviceType: (width: number) => string
}

interface MediaInfo {
  width: number | undefined,
  height: number | undefined,
  deviceType: string
}

const BREAKPOINTS:Breakpoints = {
  mobile: 375,
  tablet: 768,
  desktopXs: 992,
  desktop: 1440,
  getDeviceType(width) {
    return width < this.tablet ? 'mobile' :
      width < this.desktopXs ? 'tablet' :
        'desktop'
  }
}

const getInfo = (isClient: boolean): MediaInfo => {
  return isClient ? {
    width: window.innerWidth,
    height: window.innerHeight,
    deviceType: BREAKPOINTS.getDeviceType(window.innerWidth)
  } : { width: undefined, height: undefined, deviceType: 'desktop'}
}

function debounce(func: Function, miliseconds = 600) {
  let timer:number
  return function (event: any) {
    if (timer) clearTimeout(timer)
    timer = setTimeout(func, miliseconds, event)
  }
}

export const useDeviceType = () => {
  const isClient = typeof window !== 'undefined'
  const [deviceType, setDeviceType] = useState(getInfo(isClient).deviceType)

  const handleResize = () => {
    const info = getInfo(isClient)
    if (info.deviceType !== deviceType) setDeviceType(info.deviceType)
  }
  const debouncedHandler = debounce(handleResize)

  useEffect(() => {
    if (!isClient) return () => { }

    window.addEventListener('resize', debouncedHandler, { passive: true })
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', debouncedHandler)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return deviceType
}

// TODO INJECT IN THE HOOK/getServerSideProps/getStaticProps FOR SSR DEVICE DETECTION
// Review blocks in the client were have different component base on device (must be a merge with the resize or something to avoid broken layouts when resize from desktop to mobile)

// const isMobile = (ctx = null) => {
//   const isClient = typeof window !== 'undefined'
//   let UA = null
//   if (isClient) {
//     UA = navigator.userAgent
//   } else {
//     const userAgentHeader = ctx?.req?.headers?.['user-agent'] ?? ctx?.headers?.['user-agent']
//     if (userAgentHeader) UA = userAgentHeader
//   }

//   return UA ? Boolean(UA.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i)) : 'desktop'
// }
