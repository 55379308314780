/* eslint-disable quotes */
export const home = (data) => {
  return JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "",
    "name": "Sablos",
    "url": "https://sablos.com",
    "isPartOf": {
      "@type": "WebSite"
    },
    "publisher": {
      "@type": "CreativeWork",
      ...(data.seo && { "name": `${data.seo.title}` }),
      ...(data.seo && { "description": `${data.seo.description}` }),
      "publisher": "Sablos",
      "url": "https://sablos.com",
      "keywords": "magazine, design, articles, trending",
      "genre": "http://vocab.getty.edu/aat/300215390",
      // "sameAs": [
      //   "https://www.instagram.com/sablos/",
      //   "https://www.facebook.com/sablos",
      //   "https://twitter.com/sablos_",
      //   "https://www.youtube.com/channel/UCjCKgUwF1YaTev9ERHFL4zw",
      //   "https://www.linkedin.com/company/sablos",
      // ],
      "image": "https://sablos.com/images/og_cover.jpg",  // required CHECK
      // "logo": "https://sablos.com/images/logo.png"
    }
  })
}
