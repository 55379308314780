import Script from 'next/script'

type TypeGTM = {
  readonly id: string,
  readonly noscript: boolean
}

export const GoogleTagManager = ({ id, noscript = false }:TypeGTM) => {
  return (
    <>
      {!noscript ?
        <Script id='script-gtm'>
          {`
            https://www.googletagmanager.com/gtag/js?id=${id}';
          `}
        </Script>
        :
        <noscript>
          <iframe src={`https://www.googletagmanager.com/ns.html?id='${id}'`} height='0' width='0' style={{ display: 'none', visibility: 'hidden'}}></iframe>
        </noscript>
      }
    </>
  )
}
